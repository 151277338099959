@media print {
  head {
    display: none;
  }

  body {
    margin: 0;
    padding: 0;
    background-color: #fff;
    size: landscape;
    width: 100%;
    height: 100%;
  }

  .top-bar,
  .sidebar-main,
  .print-icon,
  .printable-page-overlay {
    display: none;
  }

  .printable-page {
    padding: 20px;
    border: 1px solid #ccc;
    background-color: #fff;
    width: 80%;
    max-height: 80vh;
    overflow-y: auto;
  }

  .heading-input input {
    font-size: 24px;
  }

  .sub-heading {
    font-size: 20px;
  }

  .students-table th,
  .students-table td {
    font-size: 16px;
    border: 1px solid #ccc;
    padding: 8px;
    text-align: center;
  }

  .students-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }

  .students-table td {
    font-size: 14px;
  }

  .students-table th[colspan] {
    background-color: #eaeaea;
  }

  .students-table th:nth-child(2),
  .students-table th:nth-child(3),
  .students-table th:nth-child(n + 4):nth-child(-n + 8) {
    width: 250px;
  }

  .students-table th:nth-child(3) {
    width: 100px;
  }

  .students-table th:nth-child(1) {
    width: 20px;
  }

  .students-table th:nth-child(n + 4):nth-child(-n + 8),
  .students-table td:nth-child(n + 4):nth-child(-n + 8) {
    width: 50px;
  }

  .students-table th:nth-child(n + 4):nth-child(-n + 8) {
    width: 40px;
  }

  .students-table td:nth-child(n + 4):nth-child(-n + 8) {
    border: 1px solid #ccc;
    width: 10px;
    height: 20px;
  }

  .cb-cell {
    border: 1px solid #ccc;
    width: 10px;
    height: 20px;
  }

  .print-button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #042855;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
  }

  /* Print header and filters at the top of each page */
  .printable-page {
    padding-top: 50px; /* Space for the header and filters */
  }

  .print-header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px;
    font-size: 18px;
    background-color: #f2f2f2;
    border-bottom: 1px solid #ccc;
    text-align: center;
  }

  .heading-input input {
    display: block;
    width: 100%;
    margin: 0;
    border: none;
    font-size: 24px;
    font-weight: bold;
  }

  .sub-heading {
    font-size: 16px;
  }

  /* Add page numbers */
  .page-number:after {
    content: counter(page);
  }
}
