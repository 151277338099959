.container {
  display: flex;
}

.sidebar-main {
  background-color: #042855;
  color: white;
  height: 100vh;
  width: 20%;
  position: fixed;
  overflow-y: scroll;
}

.sidebar-content {
  height: 90vh;
  overflow-y: scroll;
}

.sidebar-top {
  height: 10%;
  position: fixed;
  top: 0;
  width: 20%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  padding-left: 10px;
  text-align: center;
}

.nav-item {
  width: 100%;
}

.nav-link {
  width: 100%;
  margin-right: 0;
  margin-left: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: clip;
  font-size: 14px;
  text-align: start;
  margin-top: 2px;
  margin-bottom: 2px;
  color: white;
  border: none;
  border-radius: 10px;
  outline: none;
  cursor: pointer;
}

.nav-link:hover {
  background-color: #15457fa8;
  color: white;
}

.nav-link:focus {
  outline: none;
}

.selected {
  background-color: #15457fa8;
  color: white;
  text-emphasis-color: white;
}

.top-bar {
  height: 10vh;
  position: fixed;
  top: 0;
  left: 20%;
  right: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 0 20px;
}

.main-content {
  width: 80%;
  height: 90vh;
  margin-left: 20%;
  padding: 20px;
  margin-top: 10vh;
  overflow-y: scroll;
  background-color: #dbebffa8;
}

.segment-title {
  display: flex;
  align-items: center;
  margin-left: 10px;
  color: #042855;
}

.marking {
  display: inline-block;
  width: 5px;
  height: 100%;
  background-color: #042855;
  margin-right: 5px;
}

.top-icons {
  display: flex;
  align-items: center;
}

.top-icons i {
  color: #042855;
  padding: 0 10px;
  transition: transform 0.3s ease;
}

.top-icons i:hover {
  cursor: pointer;
  transform: scale(1.5);
}

.content-card {
  width: 35vh;
  height: 20vh;
  background-color: white;
  color: #042855;
  border: 1px solid #042855;
  border-radius: 8px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  cursor: pointer;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.content-card:hover {
  transform: scale(1.1);
}

.card-icon {
  font-size: 24px;
  margin-bottom: 5px;
  margin-top: 5px;
  margin-left: 20px;
}

.card-text {
  font-weight: bold;
  margin-left: 15px;
  margin-right: 15px;
}

.card-sub-text {
  font-weight: normal;
  color: green;
}

.home-segment {
  height: 100%;
  width: 100%;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  grid-row-gap: 25px;
  margin-left: 25px;
  margin-right: 20px;
  margin-top: 10px;
}

.larger-card-holder {
  display: flex;
  margin-left: 25px;
}

.larger-card {
  height: 15vh;
  background-color: white;
  color: #042855;
  border: 1px solid #042855;
  border-radius: 8px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  display: flex;
  width: 60vh;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  margin-right: 25px;
}

.chart-container {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  align-items: center;
}

.chart-title {
  margin-top: 10px;
  font-weight: bold;
  color: #042855;
}

.quick-links {
  margin-top: 20px;
  margin-left: 20px;
}

.quick-links-heading {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.quick-links-container {
  display: flex;
  flex-wrap: wrap;
}

.quick-links-card {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 100px;
  background-color: white;
  border-radius: 5px;
  border: 1px solid #042855;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  margin: 10px;
  padding: 10px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.quick-links-card:hover {
  transform: scale(1.1);
}

.quick-links-card i {
  font-size: 24px;
  color: #042855;
  margin-right: 10px;
}

.quick-links-card span {
  font-weight: bold;
  color: #042855;
}

.charts {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}

.chart {
  width: 48%;
  height: 70vh;
  margin-bottom: 20px;
  background-color: #f2f2f2;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
}

.chart-container-s {
  height: 55vh;
}

.chart-title {
  margin-bottom: 10px;
  font-size: 18px;
  font-weight: bold;
}

.component-screen {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.header-comp {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}

.back-btn-sg {
  cursor: pointer;
  transition: transform 0.2s ease;
  justify-self: start;
  align-self: start;
  margin-top: 0;
  color: #042855;
  margin-left: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.back-btn-sg i {
  margin-right: 5px;
}

.back-btn-sg:hover {
  transform: scale(1.1);
}

.header-txt {
  font-weight: bold;
  color: #042855;
  justify-self: center;
  align-self: center;
}

.header-txt h3 {
  margin: 0;
}

.component-content {
  margin-top: 15px;
}

.component-content {
  overflow-y: scroll;
}

.avatar-upload {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1rem;
}

.avatar-container {
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}

.avatar-label {
  display: block;
  cursor: pointer;
}

.avatar-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.avatar-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.avatar-overlay:hover {
  opacity: 1;
}

.avatar-overlay i {
  color: #fff;
  font-size: 24px;
}

.avatar-input {
  display: none;
}

.name-inputs {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.input-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
}

label {
  font-weight: bold;
}

input {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.date-picker {
  margin-bottom: 1rem;
}

.gender-select {
  margin-bottom: 1rem;
}

.class-level-select {
  margin-bottom: 1rem;
}

select {
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.address-inputs {
  margin-bottom: 1rem;
}

input[type="text"],
textarea {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.guardian-info {
  margin-bottom: 1rem;
}

.guardian-info h3 {
  margin-bottom: 0.5rem;
}

.guardian-inputs label {
  font-weight: bold;
}

.guardian-inputs input[type="text"],
.guardian-inputs textarea,
.guardian-inputs select {
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 0.5rem;
}

.checkbox-group label {
  margin-left: 0.5rem;
}

input[type="checkbox"] {
  margin-right: 0.5rem;
}

.save-button {
  display: inline-block;
  padding: 0.5rem 1rem;
  background-color: blue;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.save-button:hover {
  background-color: darkblue;
}

.student-list-container {
  height: 75vh;
  overflow-y: scroll;
}

.search-bar {
  margin-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.search-input {
  width: 75%;
  padding: 10px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 14px;
}

.filters-section {
  margin-bottom: 20px;
}

.filter-label {
  font-size: 14px;
  margin-right: 10px;
  margin-left: 10px;
}

.filter-select {
  padding: 8px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 14px;
}

.filters-display {
  margin-bottom: 10px;
}

.filter-tag {
  display: inline-block;
  background-color: #f5f5f5;
  color: #555555;
  font-size: 14px;
  padding: 5px 8px;
  margin-right: 5px;
  border-radius: 4px;
}

.student-container {
  margin-bottom: 20px;
}

.student-card {
  display: flex;
  align-items: center;
  padding-top: 10px;
  padding-left: 10px;
  background-color: #f5f5f5;
  border-radius: 8px;
  border: 0.1px solid #042855;
  margin-bottom: 10px;
  margin-right: 10px;
}

.student-avatar img {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.student-details {
  width: 100%;
  font-size: 14px;
  font-style: italic;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.st-st-div {
  margin-left: 10px;
}

.student-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.student-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.student-gender,
.student-age {
  margin: 0;
  font-style: italic;
  font-size: 14px;
  margin-right: 10px;
}

.student-class {
  display: flex;
  align-items: center;
  margin-right: 10px;
  justify-self: flex-end;
}

.student-class-level,
.student-class-arm {
  margin: 0;
  font-size: 14px;
  margin-right: 10px;
}

.student-id {
  color: gray;
  margin-top: 5px;
  font-size: 14px;
}

.print-icon {
  cursor: pointer;
  background-color: blue;
  color: #ffffff;
  padding: 10px;
  border-radius: 4px;
  display: inline-block;
  margin-top: 10px;
}

.print-icon i {
  margin-right: 5px;
}

.printable-page {
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #fff;
}

.print-close {
  cursor: pointer;
  font-size: 20px;
  float: right;
  color: #555;
}

.heading-input {
  outline: 2px solid #ccc;
  padding: 5px;
  border-radius: 4px;
}

.heading-input input {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
  width: 100%;
  border: none;
  outline: none;
}

.sub-heading {
  font-size: 16px;
  margin-bottom: 15px;
}

.students-table {
  width: 100%;
  border-collapse: collapse;
}

.students-table th,
.students-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: center;
}

.students-table th {
  background-color: #f2f2f2;
  font-weight: bold;
}

.students-table td {
  font-size: 14px;
}

.students-table th[colspan] {
  background-color: #eaeaea;
}

.students-table th:nth-child(2),
.students-table th:nth-child(3),
.students-table th:nth-child(n + 4):nth-child(-n + 8) {
  width: 250px;
}

.students-table th:nth-child(3) {
  width: 100px;
}
.students-table th:nth-child(1) {
  width: 20px;
}
.students-table th:nth-child(n + 4):nth-child(-n + 8),
.students-table td:nth-child(n + 4):nth-child(-n + 8) {
  width: 50px;
}

.students-table th:nth-child(n + 4):nth-child(-n + 8) {
  width: 40px;
}

.students-table td:nth-child(n + 4):nth-child(-n + 8) {
  border: 1px solid #ccc;
  width: 10px;
  height: 20px;
}

.cb-cell {
  border: 1px solid #ccc;
  width: 10px;
  height: 20px;
}

.printable-page-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.printable-page {
  padding: 20px;
  border: 1px solid #ccc;
  background-color: #fff;
  width: 80%;
  max-height: 80vh;
  overflow-y: auto;
}

.print-button1 {
  margin-top: 20px;
  padding: 10px 20px;
  background-color: #042855;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
}

.popup-overlay2 {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.student-details-popup {
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 90%;
  overflow-y: auto;
  max-height: 90%;
}

.student-details-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.student-avatar img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 10px;
}

.avatar-edit-icon {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: #1e4cd7;
  color: #ffffff;
  padding: 4px;
  border-radius: 50%;
  cursor: pointer;
}

.student-name h4 {
  font-size: 20px;
  margin: 0;
}

.student-name p {
  font-size: 14px;
  color: gray;
  margin: 0;
}

.close-icon {
  cursor: pointer;
  font-size: 20px;
  margin-left: auto;
  color: #555;
}

.student-details-content {
  border-top: 1px solid #ccc;
  padding-top: 20px;
  margin-top: 20px;
}

.student-info-section,
.guardian-info-section {
  margin-bottom: 20px;
}

.student-info-section h4,
.guardian-info-section h4 {
  font-size: 18px;
  margin-bottom: 10px;
}

.info-field {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.info-field label {
  width: 180px;
  font-weight: bold;
}

.info-field input,
.info-field select {
  flex: 1;
  padding: 8px;
  border: 1px solid #dddddd;
  border-radius: 4px;
  font-size: 14px;
}

.edit-save-btn button {
  padding: 8px 16px;
  background-color: #042855;
  color: #ffffff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  cursor: pointer;
  transition: background-color 0.2s;
}

.student-details-popup {
  width: 80%;
  max-width: 600px;
}

.close-icon:hover {
  color: #ff0000;
}

.edit-save-btn button:hover {
  background-color: #1e4cd7;
}

.info-field input:disabled,
.info-field select:disabled {
  background-color: #f0f0f0;
}

.info-field select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%23000000' viewBox='0 0 24 24' width='18' height='18'%3E%3Cpath d='M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: right 8px center;
  padding-right: 28px;
}

.info-field select::-ms-expand {
  display: none;
}

.avatar-save {
  cursor: pointer;
  color: #042855;
}

.avatar-save:hover {
  color: #1e4cd7;
}

.teacher-main {
  display: flex;
  flex-direction: column;
}

.teacher-top-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.teacher-heading {
  font-weight: bold;
  color: #042855;
  font-size: 24px;
  margin: 0;
}

.teacher-top-icons {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  transition: transform 0.3s ease;
  text-align: center;
}

.teacher-top-icons i {
  display: block;
}

.teacher-top-icons:hover {
  transform: scale(1.5);
}

.teacher-top-icons:hover::after {
  content: attr(title);
  background-color: #042855;
  color: #ffffff;
  font-size: 12px;
  padding: 5px 8px;
  border-radius: 4px;
  position: absolute;
  top: 20px;
  transform: translateX(-80%);
  z-index: 999;
  white-space: nowrap;
  opacity: 0.75;
  width: 120px;
}

.teacher-container {
  overflow-y: auto;
  flex: 1;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  max-height: 75vh;
}

.teacher-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 5px;
  margin-top: 10px;
}

.teacher-content-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ffffff;
  border: 1px solid #042855;
  border-radius: 8px;
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
  overflow: auto;
  width: 200px;
  height: 125px;
  text-align: center;
  padding: 10px;
  margin: 10px;
}

.teacher-content-card:hover {
  transform: scale(1.05);
}

.teacher-avatar img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
}

.teacher-details {
  margin-top: 10px;
}

.teacher-name {
  font-weight: bold;
  font-size: 12px;
  margin-bottom: 3px;
  color: #042855;
}

.teacher-gender {
  font-style: italic;
  font-size: 12px;
  color: gray;
}

.teacher-icons {
  display: flex;
  align-self: flex-end;
}

.teacher-icon {
  font-size: 10px;
  color: #042855;
  margin-left: 10px;
  cursor: pointer;
  transition: color 0.2s;
  transition: transform 0.3s ease;
}

.teacher-icon:hover {
  transform: scale(1.25);
}

.teacher-icon:hover::after {
  content: attr(title);
  background-color: #042855;
  color: #ffffff;
  font-size: 12px;
  padding: 5px 8px;
  border-radius: 4px;
  position: absolute;
  top: 20px;
  transform: translateX(-100%);
  z-index: 999;
  white-space: nowrap;
  opacity: 0.75;
  width: 100px;
}

.teacher-card-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.create-teacher-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;
}

.create-teacher-content {
  background-color: #ffffff;
  max-width: 90vh;
  max-height: 80vh;
  overflow-y: auto;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

.create-teacher-content i.fas.fa-times {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
  color: #555;
}

.create-teacher-content h2 {
  margin-bottom: 20px;
}

.create-teacher-content form {
  display: flex;
  flex-direction: column;
}

.create-teacher-content label {
  margin-bottom: 10px;
}

.create-teacher-content input {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 15px;
}

.create-teacher-content .buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.create-teacher-content button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.create-teacher-content button:first-child {
  background-color: #042855;
  color: #ffffff;
}

.create-teacher-content button:last-child {
  background-color: #ff0000;
  color: #ffffff;
  border: none;
}

.create-teacher-content button:hover {
  background-color: #1e4cd7;
  color: #ffffff;
}

.create-teacher-content button:last-child:hover {
  background-color: #ff3333;
}

.subject-list-popup {
  position: absolute;
  top: 35%;
  right: 35%;
  width: 250px;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 10px;
  border-radius: 4px;
  z-index: 1000;
}

.subject-list-popup .subject-list-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.subject-list-popup .subject-list-header h3 {
  margin: 0;
}

.subject-list-popup .fa-times {
  cursor: pointer;
  font-size: 18px;
}

.subject-list-popup .subject-list {
  max-height: 200px;
  overflow-y: auto;
}

.subject-list-popup .subject-item {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.subject-list-popup .subject-title {
  margin: 0;
}

.subject-list-popup .subject-class {
  margin: 0;
  font-size: 12px;
}

.delete-confirmation-popup {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 99999;
}

.delete-confirmation-popup p {
  margin-bottom: 15px;
}

.delete-confirmation-buttons {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 15px;
}

.delete-confirmation-buttons button {
  padding: 8px 16px;
  background-color: #ff0000;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
}

.delete-confirmation-buttons button:hover {
  background-color: #cc0000;
}

.admin-segment-container {
  background-color: #ffffff;
  color: #042855;
  padding: 20px;
  border-radius: 10px;
}

.admin-card {
  display: flex;
  align-items: center;
  border: 1px solid #042855;
  padding: 15px;
  margin: 10px 0;
  border-radius: 8px;
  background-color: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.admin-image {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 20px;
}

.admin-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
}

.admin-actions i {
  cursor: pointer;
  margin: 0 10px;
  color: #042855;
}

.admin-actions i:hover {
  color: #03559C;  
}

.admin-list-container {
  max-height: 70vh;
  overflow-y: auto;  
  padding-right: 15px; 
}

.admin-popup-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px; 
  padding: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
  z-index: 1000; 
}

.admin-popup-container .add-admin-icon {
  cursor: pointer;
  font-size: 24px;
  color: #042855;
}

.admin-popup-container .add-admin-icon:hover {
  color: #03559C;
}

.admin-popup-container .image-upload {
  position: relative;
  width: 100px;
  height: 100px;
}

.admin-popup-container .admin-thumbnail {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.admin-popup-container .camera-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
  font-size: 24px;
  opacity: 0.7;
}

.admin-popup-container button {
  background-color: #042855;
  color: white;
  border: none;
  padding: 8px 16px;
  margin: 5px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.admin-popup-container button:hover {
  background-color: #0356a0; 
}

.admin-popup-container button:active {
  background-color: #021c3d;
}

.image-upload .camera-icon {
  color: #042855;
  background-color: rgba(0, 0, 0, 0.5); 
  border-radius: 50%;
  padding: 5px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  cursor: pointer;
  transition: color 0.3s ease;
}

.image-upload:hover .camera-icon {
  color: #ffffff; 
}

.admin-popup-container .buttons-wrapper {
  display: flex;
  justify-content: space-between; 
  margin-top: 15px;  
}
